export function isAdmin(to, from, next) {
    // Assuming your store has a module named 'user' that contains user information
    const userRole = localStorage.getItem('type');
  
    if (userRole === 'Admin') {
      next();
    } else {
      next('/login'); // Redirect to home or login page
    }
  }
  
  export function isUser(to, from, next) {
    // Assuming your store has a module named 'user' that contains user information
    const userRole = localStorage.getItem('type');
  
    if (userRole === 'User' || userRole === 'Useradmin' ) {
      next();
    } else {
      next('/login'); // Redirect to home or login page
    }
  }