/*
 =========================================================
 * Vue Black Dashboard - v1.1.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2023 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import { pinia } from "./store";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import VueCookies from "vue-cookies";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/js/dist/dropdown"; // Example - adjust as needed

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { faHome, faInfoCircle, faCogs, faEnvelope } from "@fortawesome/free-solid-svg-icons";

// library.add(faHome, faInfoCircle, faCogs, faEnvelope);
// Vue.component("font-awesome-icon", FontAwesomeIcon);
import Trend from "vuetrend";

Vue.use(Trend);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueCookies);
Vue.use(VueSweetalert2);
Vue.use(pinia);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

router.beforeEach((to, from, next) => {
  console.log(to.name);
  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    // Implement your authentication check logic here
    const isAuthenticated = checkIfUserIsAuthenticated(); // Replace this with your actual authentication check
    if (isAuthenticated) {
      // User is authenticated, allow access
      next();
    } else {
      // User is not authenticated, redirect to login or another route
      next("/login"); // Replace '/login' with your login route
    }
  } else {
    // Route doesn't require authentication, allow access
    next();
  }
});

function checkIfUserIsAuthenticated() {
  const authToken = localStorage.getItem("token");
  return !!authToken;
}

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
