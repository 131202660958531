<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"

          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
      </div>
      <button
        class="navbar-toggler"
        type="button"

        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown
              tag="li"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="#"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div class="photo">
                  <img src="img/anime3.png" />
                </div>

              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Profile</a>
              </li>
              <!-- <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Settings</a>
              </li> -->
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a  @click="logout()" class="nav-item dropdown-item " style="color:black !important;">Log out</a>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import Modal from '@/components/Modal';
import axios from "axios";

export default {
  components: {
    CollapseTransition,
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return name ;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    // capitalizeFirstLetter(string) {
    //   return string;
    // },
    // toggleNotificationDropDown() {
    //   this.activeNotifications = !this.activeNotifications;
    // },
    // closeDropDown() {
    //   this.activeNotifications = false;
    // },
    // toggleSidebar() {
    //   this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    // },
    // hideSidebar() {
    //   this.$sidebar.displaySidebar(false);
    // },
    // toggleMenu() {
    //   this.showMenu = !this.showMenu;
    // },

    async logout() {
    try {
     // const response = await axios.get("https://admin.lotti.webmarken.com/api/auth/logout");
      localStorage.removeItem('token');
      this.$router.push("/login");
    } catch (error) {
      console.log(error);
      // Handle error cases, e.g., show an error message to the user
      alert("Login failed. Please try again.");
    }
  },

  }
};
</script>
<style>
.dropdown-toggle::after{
  display:none !important;
}
</style>
